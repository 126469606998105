import React, { FC } from 'react';
import {Correctness} from "shared/lib/constants/Correctness";
import styled from 'styled-components/macro';
import BottomMessageToast, {BottomMessageToastProps} from "../../modules/components/BottomMessageToast";
import {ThumbsUpIcon} from "./Icons";

interface Props extends Pick<BottomMessageToastProps, 'buttonLabel' | 'onButtonClick'> {
  correctness: Correctness;
  message?: string;
  hideButton?: boolean
}

const CorrectIncorrectMessageToast: FC<Props> = ({correctness, onButtonClick, message, ...rest}) => (
    <BottomMessageToast
      message={message ?? correctness}
      onButtonClick={onButtonClick}
      icon={
        correctness === Correctness.CORRECT ? <ThumbsUpIcon /> : "!"
      }
      iconBackgroundColor={correctness === Correctness.CORRECT ? '#4DACBE' : '#ff6762'}
      {...rest}
    />
);


export default styled(CorrectIncorrectMessageToast)``;
