type ErrorWithResponse = Error & {
  response?: {
    data?: {
      error?: {
        message: string;
      };
    };
  };
};

export default function getErrorMessage(error: ErrorWithResponse): string {
  const errorMessage =
    (error &&
      error.response &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.message) ||
    error.message ||
    'Unknown error';

  return errorMessage;
}
