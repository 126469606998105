import React, { FC, MouseEventHandler } from "react";
import styled, { css } from "styled-components/macro";
import BlockButton from "@emberex/components/lib/BlockButton";
import { PURPLE, YELLOW, DARKEST_BLUE } from "../../colors";
import hidable from "../../common/utils/hidable";

interface Props {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  dark?: boolean;
  hide?: boolean;
}

const PillButton: FC<Props> = (props) => {
  return <BlockButton {...props} />;
};

export default styled(PillButton)<{ light?: boolean; hide?: boolean }>`
  padding-top: 0;
  padding-bottom: 0;
  height: 70px;
  border-radius: 35px;
  border: 5px solid #0f2f4d;
  font-size: 1.25rem;
  font-weight: 500;
  /* Hide instantly, but fade in */
  transition: ${(props) => (props.hide ? "none" : "opacity 0.2s")};
  flex-shrink: 0;

  &:disabled {
    opacity: 0.4;
  }

  ${(props) => {
    const { dark = true } = props;

    if (dark) {
      return css`
        background-color: ${PURPLE};
        color: white;
      `;
    }

    return css`
      background-color: ${YELLOW};
      color: ${DARKEST_BLUE};
    `;
  }}
  ${hidable}
`;
