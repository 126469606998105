import styled from "styled-components/macro";
import { Link as ReactRouterLink } from "react-router-dom";

const Link = styled(ReactRouterLink)`
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background-color: transparent;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  display: flex;
  flex-direction: row;
  text-align: inherit;
  text-decoration: none;
  cursor: pointer;
`;

export default Link;
